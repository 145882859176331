define(['santa-components', 'lodash', 'prop-types', 'componentsCore'], function (santaComponents, _, PropTypes, componentsCore) {
    'use strict';

    function getTableBody() {
        const tableRows = _.times(this.props.compProp.numOfRows, function (rowIndex) {
            const cells = _.times(this.props.compProp.numOfColumns, function (index) {
                const cell = this.props.getBodyCell(index, rowIndex);
                const ref = `cell_${rowIndex}_${index}`;
                const props = {
                    style: this.props.compData.columnsStyle[index],
                    ref,
                    key: ref
                };
                return santaComponents.utils.createReactElement('td', props, cell);
            }.bind(this));

            return santaComponents.utils.createReactElement('tr', {key: `row_${rowIndex}`, ref: `row_${rowIndex}`}, cells);
        }.bind(this));

        const spacer = santaComponents.utils.createReactElement(
            'tr',
            {key: 'row_spacer', ref: 'row_spacer', className: this.classSet({spacer: true})},
            santaComponents.utils.createReactElement('td', {colSpan: '100%'})
        );
        tableRows.push(spacer);

        return tableRows;
    }

    function getHeaderFooterCellsContent(isHeader) {
        const prefix = isHeader ? 'header' : 'footer';
        const createCellFunc = isHeader ? this.props.getHeaderCell : this.props.getFooterCell;

        return _.times(this.props.compProp.numOfColumns, function (index) {
            const cell = createCellFunc(index);
            const ref = `${prefix}_cell_${index}`;
            const props = {
                ref,
                key: ref
            };
            return santaComponents.utils.createReactElement('td', props, cell);
        });
    }

    /**
     * @class components.Table
     * @extends {core.skinBasedComp}
     */
    const table = {
        displayName: 'Table',
        mixins: [componentsCore.mixins.skinBasedComp],

        propType: {
            /**
             * Gets the columnIndex and rowIndex
             * @type {function(number, number): ReactComponent}
             */
            getBodyCell: PropTypes.func.isRequired,

            /**
             * Gets the columnIndex
             * @type {function(number): ReactComponent}
             */
            getHeaderCell: PropTypes.func.isRequired,

            /**
             * Gets the columnIndex
             * @type {function(number): ReactComponent}
             */
            getFooterCell: PropTypes.func.isRequired
        },

        getSkinProperties() {
            const skinParts = {
                tableBody: {children: getTableBody.call(this)}
            };

            if (this.props.compProp.minHeight) {
                skinParts[''] = {
                    style: {minHeight: this.props.compProp.minHeight, width: '100%'}
                };
                skinParts.table = {
                    style: {height: this.props.compProp.minHeight}
                };
            }

            if (this.props.compProp.header) {
                skinParts.tableHeader = {
                    children: santaComponents.utils.createReactElement('tr', {key: 'row_header'}, getHeaderFooterCellsContent.call(this, true))
                };
            }

            if (this.props.compProp.footer) {
                skinParts.tableFooter = {
                    children: santaComponents.utils.createReactElement('tr', {key: 'row_footer'}, getHeaderFooterCellsContent.call(this, false))
                };
            }

            return skinParts;
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.Table', table);

    return table;
});
